.faq_header_wrap{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    margin-top: 100px;
}
.faq_header{
    color: #E6E6E6;
    text-align: center;
    font-family: Montserrate, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.4px;
}
.faq_section_wrap{
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    width: 100vw;
    align-items: center;
    margin-bottom: 200px;
}
.faq_section{
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    border-radius: 6px;
    border: 1px solid #22335F;
    background: #101D40;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.30);
    width: 100vw;
    max-width: 1760px;
    height: 92px;
}
.faq_section:hover{
    border-radius: 6px;
    background: #1A2B58;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.30);
}
.faq_section_svg{
    width: 42px;
    height: 42px;
}
.faq_section_span{
    color: #FFF;
    font-family: Montserrat, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
}
.faq_section_btm_wrap{
    margin-top: -5px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100vw;
    max-width: 1760px;
    padding: 30px;
    border-radius: 0 0 6px 6px;
    background: #101D40;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.30);
    border: 1px solid #22335F;
}
.faq_section_btm_wrap_hr{
    width: 100%;
    height: 1px;
    background: rgba(0, 148, 255, 0.14);
}
.faq_section_btm_wrap_span{
    color: rgba(255, 255, 255, 0.60);
    font-family: Montserrat, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 160% */
}
.box_bg{
    position: absolute;
    margin-top: -5px;
    z-index: -50;
    width: 100%;
}
@media (max-width: 425px) {
    .faq_header_wrap{
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        margin-top: 50px;
    }
    .faq_section_wrap{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 14px 0;
        width: 100vw;
        align-items: center;
        margin-bottom: 200px;
    }
    .faq_section_span{
        color: #FFF;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 133.333% */
    }
    .faq_section_btm_wrap_span{
        color: rgba(255, 255, 255, 0.60);
        font-family: Montserrat, serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 160% */
    }
    .faq_section{
        display: flex;
        padding-left: 20px;
        padding-right: 20px;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        border-radius: 6px;
        border: 1px solid #22335F;
        background: #101D40;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.30);
        width: 90vw;
        max-width: 1760px;
        height: 92px;
    }
    .faq_section_btm_wrap{
        margin-top: -5px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 90vw;
        max-width: 1760px;
        padding: 30px;
        border-radius: 0 0 6px 6px;
        background: #101D40;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.30);
        border: 1px solid #22335F;
    }
}