.leaders_wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px;
    border-radius: 6px;
    border: 1px solid #22335F;
    background: #101D40;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.30);
    margin: 70px 80px 210px;
}
.leaders_period_wrap {
    width: auto;
    margin: 80px auto;
    display: flex;
    gap: 100px;
}
.leaders_period_text {
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0%;
    text-align: left;
}
.leaders_period_text_active {
    margin-top: 18px;
    border: 4px solid rgb(255, 199, 0);
}
.leaders_table_header {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-around;
    background: rgb(12, 22, 50);
    padding: 15px 70px;
    text-align: left;
}
.leaders_table_header_text{
    color: rgba(255, 255, 255, 0.5);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0%;
}
.leaders_table_row{
    display: grid;
    text-align: left;
    place-self: center;
    grid-template-columns: repeat(4, 1fr);
    height: 70px;
    width: 100%;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
    background: linear-gradient(180.00deg, rgb(12, 22, 50) 6.879%,rgb(19, 35, 78) 51.111%,rgb(12, 22, 50) 100%);
    margin-bottom: 20px;
}
.leaders_table_row_frst_item{
    margin-left: 51px;
    display: flex;
    height: 100%;
    align-items: center;
    gap: 10px;
}
.leaders_table_row_frst_item_img{
    max-height: 70px;
}
.leaders_table_row_frst_item_text{
    display: flex;
    height: 100%;
    align-self: center;

    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0%;
    color: rgb(255, 255, 255);
}
.leaders_table_row_frst_item_text_gold{
    color: rgb(253, 225, 79);
}
.leaders_table_row_frst_item_text_silver{
    color: rgb(201, 201, 201);
}
.leaders_table_row_frst_item_text_bronze{
    color: rgb(242, 184, 110);
}
.leaders_table_row_frst_item_text_img{
    width: 80px;
    height: 110px;
}
.leaders_table_row_scnd_item_text{
    color: rgb(255, 255, 255);
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    display: flex;
    height: 100%;
    align-items: center;
}
.leaders_table_body{
    padding: 0px 20px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
@media (max-width: 425px) {
    .leaders_table_body{
        padding: 0px 0px;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap:10px;
        width: 100%;
    }
    .leaders_table_row_scnd_item_text{
        color: rgb(255, 255, 255);
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
    }
    .leaders_table_row_frst_item_text{
        background: linear-gradient(147.00deg, rgb(253, 225, 79),rgb(199, 154, 0));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0%;
    }
    .leaders_table_row_frst_item_text_img{
        width: 62px;
        height: 84px;
    }
    .leaders_table_row_frst_item{
        margin-left: 0px;
        display: flex;
        flex-direction: row-reverse;
        gap: 10px;
    }
    .leaders_table_row{
        display: flex;
        justify-content: space-around;
        height: 100px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);
        background: linear-gradient(180.00deg, rgb(12, 22, 50) 6.879%,rgb(19, 35, 78) 51.111%,rgb(12, 22, 50) 100%);
        padding-bottom: 10px;
    }
    .leaders_table_header_text{
        color: rgba(255, 255, 255, 0.5);
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: 0%;
    }
    .leaders_table_header {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        background: rgb(12, 22, 50);
        padding: 20px 10px;
        margin-top: 25px;
    }
    .leaders_period_text_active {
        margin-top: 5px;
        border: 2px solid rgb(255, 199, 0);
    }
    .leaders_period_wrap {
        width: auto;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        margin: 30px auto;
        display: grid;
        gap: 20px;
    }
    .leaders_wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 20px;
        border-radius: 6px;
        border: 1px solid #22335F;
        background: #101D40;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.30);
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
    }
    .leaders_period_text {
        color: rgb(255, 255, 255);
        font-family: Montserrat;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0%;
        text-align: left;
    }
}