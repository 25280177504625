.notFound_main{
    background-image: url("../../../../assets/bg_notFound.webp");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.notFound_main_frst_img{
    margin-top: 130px;
}
.notFound_main_scnd_img{
    margin-top: 120px;
}
.notFound_main h1{
    margin-top: 80px;
    color: #E6E6E6;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 125% */
    letter-spacing: 0.4px;
}
.notFound_main h2{
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.40);
    font-family: Montserrat, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
}
.notFound_main div{
    margin-top: 80px;
    width: 394px;
    height: 80px;
    border-radius: 10px;
    background: #FFC700;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 6px 4px 0px rgba(255, 255, 255, 0.30) inset;
}
.notFound_main div p{
    color: #08122E;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}