.section_main{
    background-image: url("../../../../assets/box_bg_roll_items.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 1504px;
    height: 836px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    user-select: none; /* Запрещает выделение текста */
    -webkit-user-select: none; /* Chrome, Safari, Opera */
    -moz-user-select: none;    /* Firefox */
    -ms-user-select: none;     /* Internet Explorer/Edge */
}
.closePopup{
    position: absolute;
    top: 20px;
    right: 20px;
}

.section_main_h1{
    select: none;
    margin-top: 50px;
    font-family: Montserrat, serif;
    font-size: 58px;
    font-style: normal;
    font-weight: 700;
    line-height: 76px; /* 131.034% */
    letter-spacing: 0.58px;
    background: linear-gradient(180deg, #FF3A3A 0%, #FFF500 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section_main_wait_prize{
    margin-top: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1146px;
    height: 120px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.08);
}
.section_main_wait_prize_p{
    color: #FFF;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
}
.section_main_wait_prize_p_win{
    color: #FFF;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}
.section_main_scroll_wrap{
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 80px;
    width: 1145px;
    height: 370px;
    background: #141414;
    overflow: hidden;
}
.rollItems_img{
    position: absolute;
    height: 370px;
    width: 1145px;
    z-index: 60;
}

.section_main_items_wrap{
    display: flex;
    gap: 12px;
    width: 100%;
    justify-content: flex-start;
}
.item_bottom_p{
    color: #FFF;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
}

.item_bottom_wrap{
    width: 277px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item_bottom_wrap_gold{
    background: rgba(255, 153, 0, 0.30);
}
.item_bottom_wrap_yellow{
    background: rgba(255, 217, 82, 0.30);
}
.item_bottom_wrap_red{
    background: rgba(224, 64, 64, 0.30);
}
.item_bottom_wrap_silver{
    background: rgba(244, 244, 244, 0.30);
}
.item_bottom_wrap_blue{
    background: rgba(0, 148, 255, 0.30);
}
.item_wrap{
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    flex-direction: column;
    align-items: center;
    width: 277px;
    height: 300px;
    border-radius: 14px;
    border: 2px solid #F79972;
}
.item_wrap_gold{
    background: linear-gradient(180deg, rgba(255, 153, 0, 0.50) 0.08%, rgba(255, 153, 0, 0.00) 99.92%);
}
.item_wrap_yellow{
    background: linear-gradient(180deg, rgba(255, 217, 82, 0.50) 0.08%, rgba(255, 229, 135, 0.00) 99.92%);
}
.item_wrap_red{
    background: linear-gradient(180deg, rgba(224, 64, 64, 0.50) 0.08%, rgba(224, 64, 64, 0.00) 99.92%);
}
.item_wrap_silver{
    background: linear-gradient(180deg, rgba(244, 244, 244, 0.50) 0.08%, rgba(244, 244, 244, 0.00) 99.92%);
}
.item_wrap_blue{
    background: linear-gradient(180deg, rgba(0, 148, 255, 0.50) 0.08%, rgba(0, 148, 255, 0.00) 99.92%);
}

@media (max-width: 425px) {
    .rollItems_img{
        position: absolute;
        height: 226px;
        width: 370px;
        z-index: 60;
    }
    .section_main{
        background-image: url("../../../../assets/box_bg_roll_items.png");
        background-size: cover;
        background-repeat: no-repeat;
        width: 375px;
        height: 632px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        user-select: none; /* Запрещает выделение текста */
        -webkit-user-select: none; /* Chrome, Safari, Opera */
        -moz-user-select: none;    /* Firefox */
        -ms-user-select: none;     /* Internet Explorer/Edge */
        border-radius: 10px;
    }
    .section_main_h1{
        select: none;
        margin-top: 90px;
        font-family: Montserrat, serif;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 34px; /* 131.034% */
        letter-spacing: 0.58px;
        background: linear-gradient(180deg, #FF3A3A 0%, #FFF500 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .section_main_wait_prize_p{
        color: #FFF;
        text-align: center;
        font-family: Montserrat, serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 133.333% */
    }
    .section_main_wait_prize{
        margin-top: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 335px;
        height: 112px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.08);
    }
    .section_main_scroll_wrap{
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 40px;
        width: 375px;
        height: 226px;
        background: #141414;
        overflow: hidden;
    }
    .section_main_wait_prize_p_win{
        color: #FFF;
        text-align: center;
        font-family: Montserrat, serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px; /* 133.333% */
    }
}