.modalContainer {
    background-image: url("../../../../../assets/bg_belt_full.webp");
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 800px;
    height: 438px;
    border: 3px solid rgb(26, 33, 55);
    border-radius: 20px;
    position: relative;
}

.modalImage {
    margin-top: -34px; /* заменяет mb-4 в Tailwind */
}

.modalTitle {
    background: linear-gradient(180.00deg, rgb(255, 58, 58),rgb(255, 245, 0));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-family: Montserrat, serif;
    font-size: 58px;
    font-weight: 700;
    line-height: 76px;
    letter-spacing: 1%;
    text-align: center;
}
.modalDescription_wrap{
    width: 700px;
    height: 120px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.08);
    padding-top: 26px;
    padding-bottom: 26px;
    padding-left: 94px;
    padding-right: 94px;
}
.modalDescription {
    text-align: center;
    color: rgb(255, 255, 255);
    font-family: Montserrat, serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0%;
    text-align: center;
    text-align: justify;
}
.modalClose{
    position: absolute;
    top: 20px;
    right: 20px;
}

@media (max-width: 425px) {
    .modalContainer {
        background-image: url("../../../../../assets/bg_belt_full.webp");
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 375px;
        height: 338px;
        border: 3px solid rgb(26, 33, 55);
        border-radius: 20px;
        position: relative;
        padding-bottom: 20px;
    }
    .modalTitle {
        background: linear-gradient(180.00deg, rgb(255, 58, 58),rgb(255, 245, 0));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        font-family: Montserrat, serif;
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 1%;
        text-align: center;
        margin-top: -30px;
    }
    .modalDescription {
        text-align: center;
        color: rgb(255, 255, 255);
        font-family: Montserrat, serif;
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        text-align: justify;
    }
    .modalDescription_wrap{
        width: 350px;
        height: 120px;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.08);
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 50px;
        padding-right: 50px;
    }
    .modalClose{
        position: absolute;
        top: 16px;
        right: 16px;
    }
    .modalImage {
        margin-top: 0px; /* заменяет mb-4 в Tailwind */
        width: 80%;
        height: 80%;
    }
}