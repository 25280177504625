.footer_wrap{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    background: linear-gradient(0deg, #020A1F 2.49%, rgba(2, 10, 31, 0.89) 71.96%, rgba(2, 10, 31, 0.00) 97.37%);
    z-index: 10;
    margin-top: -100px;
}
.footer_medium_wrap{
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    gap: 80px 0px;
    width: auto ;
}
.footer_nav{
    z-index: 100;
    display: flex;
    gap: 60px;
}
.footer_nav_item{
    color: rgba(255, 255, 255, 0.40);
    font-family: Montserrat, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.footer_nav_item:hover{
    color: #FFF;
}
.footer_nav_item_active{
    color: #FFF;
}
.footer_medium_bottom{
    display: flex;
    gap: 70px;
}
.footer_medium_bottom_visa{
    width: 132px;
    height: 40px;
}
.footer_medium_bottom_mastercard{
    width: 64px;
    height: 50px;
}
.footer_medium_bottom_qiwi{
    width: 111px;
    height: 50px;
}
.footer_medium_bottom_webmoney{
    width: 180px;
    height: 50px;
}
.footer_end_wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 80px;
    margin-bottom: 80px;
}

.footer_end_wrap_span{
    color: #FFF;
    text-align: right;
    font-family: Montserrat, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.footer_end_bottom_wrap{
    display: flex;
    margin-top: 14px;
    gap: 16px;
    align-items: center;
}
.footer_end_bottom_wrap_item_youtube{
    width: 40px;
    height: 40px;
}
.footer_end_bottom_wrap_item_vk{
    width: 48px;
    height: 30px;
}
.footer_end_bottom_wrap_item_telegram{
    width: 36px;
    height: 36px;
}
.footer_glove_image{
    position: relative;
    opacity: 1;
    z-index: 0;
}
@media (max-width: 425px) {
    .footer_medium_wrap{
        display: flex;
        flex-direction: column;
        margin-bottom: 80px;
        gap: 80px 0px;
        width: 100vw;
    }
    .footer_glove_image{
        position: absolute;
        opacity: 0.1;
        z-index: 0;
        display: none;
    }
    .footer_wrap{
        display: flex;
        flex-direction: column;
        width: 100vw;
        background: linear-gradient(0deg, #020A1F 2.49%, rgba(2, 10, 31, 0.89) 71.96%, rgba(2, 10, 31, 0.00) 97.37%);
        z-index: 10;
        margin-top: -100px;
    }
    .footer_nav{
        z-index: 100;
        display: flex;
        gap: 20px;
        flex-direction: column;
        align-items: center;
    }
    .footer_medium_bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .footer_end_wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 0px;
        margin-bottom: 0px;
        width: 100vw;
        margin-bottom: 120px;
    }
}