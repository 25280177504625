.liveLenta_wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
    height: auto;
    overflow: hidden;
    position: relative;
    padding: 0 0 0 50px;
    margin-top: -5px;
    z-index: 50;
}
.logo_live_lenta{
    display: block;
}
.item_wrap{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 142px;
    height: 140px;
    box-sizing: border-box;
    padding-bottom: 10px;
    padding-right: 40px;
    padding-left: 40px;
    border-radius: 0 0 8px 8px;
    overflow: hidden;
}
.item_top_div{
    width: 114px;
    height: 4px;
    border-radius: 0 0 15px 15px;
}
.item_wrap_yellow {
    background: linear-gradient(180deg, rgba(255, 217, 82, 0.30) 37.92%, rgba(255, 229, 135, 0.17) 100%);
}
.item_wrap_blue{
    background: linear-gradient(180deg, rgba(0, 148, 255, 0.30) 37.92%, rgba(0, 148, 255, 0.19) 100%);
}
.item_wrap_red{
    background: linear-gradient(180deg, rgba(224, 64, 64, 0.30) 37.92%, rgba(224, 64, 64, 0.17) 100%);
}
.item_wrap_silver{
    background: linear-gradient(180deg, rgba(244, 244, 244, 0.30) 37.92%, rgba(244, 244, 244, 0.19) 100%);
}
.item_wrap_burgundy{
    background: linear-gradient(180deg, rgba(255, 153, 0, 0.30) 37.92%, rgba(255, 153, 0, 0.17) 100%);
}
.item_wrap_purple{
    background: linear-gradient(180deg, rgba(189, 0, 255, 0.30) 37.92%, rgba(189, 0, 255, 0.17) 100%);
}

.item_top_div_yellow {
    background-color: #FFC700;
    box-shadow: 0 4px 30px #FFC700, 0 4px 16px #FFC700;
}
.item_top_div_blue{
    background-color: #0094FF;
    box-shadow: 0 4px 30px #0094FF, 0 4px 16px #0094FF;
}
.item_top_div_red{
    background-color: #E04040;
    box-shadow: 0 4px 30px #E04040, 0 4px 16px #E04040;
}
.item_top_div_silver{
    background-color: #F4F4F4;
    box-shadow: 0 4px 30px #F4F4F4, 0 4px 16px #F4F4F4;
}
.item_top_div_burgundy{
    background-color: #F90;
    box-shadow: 0 4px 30px #F90, 0 4px 16px #F90;
}
.item_top_div_purple{
    background-color: #BD00FF;
    box-shadow: 0 4px 30px #BD00FF, 0 4px 16px #BD00FF;
}
.items_wrap{
    display: flex;
    gap: 2px;
    margin-left: -6px;
}
.item_icon{

}


.liveLenta_logo{
    width: 60px;
    height: 96px;
}
.swiper-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: #f0f0f0;
    color: #333;
    font-size: 24px;
    border: 1px solid #ddd;
}
.item_image{
    width: auto;
    height: auto;
    max-width: 88px;
    max-height: 88px;
}
.item_bottom_text{
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
}
@media (max-width: 425px) {
    .item_top_div{
        width: 64px;
        height: 4px;
        border-radius: 0 0 15px 15px;
    }
    .item_bottom_text{
        font-family: Montserrat, serif;
        font-style: normal;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 500;
        font-size: 11px;
        line-height: 14px;
        letter-spacing: 0%;
        text-align: center;
    }
    .item_image{
        width: auto;
        height: auto;
        max-width: 50px;
        max-height: 50px;
    }
    .logo_live_lenta {
        display: none; /* Скрываем элемент на мобильных устройствах */
    }
    .item_wrap{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding-bottom: 6px;
        padding-right: 10px;
        padding-left: 10px;
        border-radius: 0 0 8px 8px;
        overflow: hidden;
        width: 92px;
        height: 100px;
    }
    .liveLenta_wrap{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100vw;
        height: auto;
        overflow: hidden;
        position: relative;
        padding: 0px;
        margin-top: -5px;
        z-index: 50;
    }
}