.header{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 80px;
    height: 110px;
    background: #08122E;
}
.nav_wrap{
    display: flex;
    align-items: center;
    height: 100%;
    gap: 60px;
    z-index: 70;
}

.nav_item{
    color: rgba(255, 255, 255, 0.40);
    font-family: Montserrat, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}
.nav_active{
    color: #FFF;
}
.belt_wrap{
    z-index: 40;
    margin-left: -80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.coin_wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
}
.coin_wrap_item_top{
    display: flex;
    align-items: center;
    gap: 6px;
}
.coin_wrap_item_top_gold{
    color: #FFC700;
    font-family: Montserrat, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.coin_wrap_item_bottom{
    color: rgba(255, 255, 255, 0.40);
    font-family: Montserrat, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    white-space: nowrap;
}
.coin_wrap_item_bottom_white{
    color: #FFF;
    margin-left: 4px;
    margin-right: 4px;
}
.wallet_wrap{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
}
.wallet_wrap_item_top{
    display: flex;
    align-items: center;
    gap: 6px;
}
.wallet_wrap_item_top_text{
    color: #FFF;
    font-family: Montserrat, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.wallet_wrap_item_bottom_text{
    color: rgba(255, 255, 255, 0.40);
    font-family: Montserrat, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}
.wallet_wrap_item_bottom_text:hover{
    color: #FFF;
    animation-duration: 150ms;
}
.profile_user_wrap{
    display: flex;
    align-content: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 8px;
}
.profile_user_wrap_top_text{
    color: #FFF;
    text-align: right;
    font-family: Montserrat, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.profile_user_wrap_bottom_wrap{
    width: 96px;
    height: 24px;
    border-radius: 18px;
    background: #FFC700;
    box-shadow: 0px -3px 1px 0px rgba(0, 0, 0, 0.22) inset, 0px 3px 1px 0px rgba(255, 255, 255, 0.41) inset;
    display: flex;
    align-items: center;
    justify-content: center;
}
.profile_user_wrap_bottom_wrap_text{
    color: #08122E;
    font-family: Montserrat, serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px; /* 140% */
}
.profile_user_icon{
    margin-left: 12px;
    width: 50px;
    height: 50px;
    border-radius: 8px;
    border: 2px solid #0386FF;
}
.profile_user_select_wrap{
    margin-left: 10px;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.10);
}
.profile_wrap{
    display: flex;
    align-items: center;
}
.balance_wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 60px;
    /*gap: 130px;*/
}
.user_balance_wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 100px;
}
.logo_nav_wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 90px;
}
.header_logo{
    width: 114px;
    height: 70px;
    min-width: 114px;
}
.header_mobile_menu{
    display: none;
}
.logo_nav_mobile_wrap{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.wallet_wrap_item_top_mobile_text{
    display: none;
}
.nav_wrap_mobile_menu{
    display: none;
}
@media (max-width: 425px) {
    .coin_wrap_item_bottom{
        color: rgba(255, 255, 255, 0.40);
        font-family: Montserrat, serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        white-space: nowrap;
    }
    .coin_wrap{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: start;
        gap: 20px;
    }
    .belt_wrap{
        z-index: 40;
        margin-left: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .wallet_wrap_item_top_mobile_text{
        color: rgb(255, 255, 255);
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0%;
        text-align: left;
        margin-right: 22px;
        display: block;
    }
    .balance_wrap_mobile_delimetr{
        width: 100%;
        height: 2px;
        background-color: rgb(20, 30, 58);
    }
    .balance_wrap_mobile{
        margin-top: 44px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 30px;
    }
    .header_mobile_menu_wrapper{
        display: block;
        z-index: 90;
        width: 78vw;
        height: 100vh;
        position: absolute;
        box-shadow: 12px 0px 18px 0px rgba(0, 0, 0, 0.6);
        background: rgb(8, 18, 46);
        left: 0;
        top: 0;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .header_mobile_menu{
        display: block;
        height: 40px;
        width: 40px;
    }
    .nav_wrap {
        display: none;
        align-items: center;
        height: 100%;
        gap: 60px;
        z-index: 50;
    }
    .nav_wrap_mobile_menu {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 20px;
        z-index: 50;
    }
    .balance_wrap{
        display: none;
        align-items: center;
        justify-content: flex-start;
        gap: 60px;
        /*gap: 130px;*/
    }
    .header_logo{
        width: 100px;
        height: 52px;
    }
    .header{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 20px;
        height: 90px;
        background: #08122E;
    }
    .logo_nav_wrap{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0px;
    }
  }