.return_to_main_wrap{
    margin-left: 80px;
    margin-top: 55px;
    display: flex;
}
.return_to_main_link{
    display: flex;
}
.return_to_main_img{
    transform: rotate(-180deg);
}
.return_to_main_text{
    margin-left: 10px;
    color: rgba(255, 255, 255, 0.60);
    font-family: Montserrat, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
}
.return_to_main_text:hover{
    color: #FFF;
}
.gift_wrap{
    display: flex;
    gap: 20px;
    margin-top: 35px;
    width: 100vw;
    justify-content: center;
}
.gift_img{

}
.gift_text{
    color: #E6E6E6;
    font-family: Montserrat, serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 125% */
    letter-spacing: 0.4px;
}

.section_lvl_wrap{
    margin-top: 80px;
    min-width: 1000px;
    padding-left: 80px;
    padding-right: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 120px;
}
.section_item_wraps{
    z-index: 90;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.section_item_wraps_bottom{
    margin-bottom: 300px;
}
.section_item_top_wrap{
    display: flex;
    align-items: center;
}
.section_item_top_text{
    color: #E6E6E6;
    font-family: Montserrat, serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 133.333% */
    letter-spacing: 0.3px;
}
.section_item_top_text_warning{
    color: #E35959;
    font-family: Montserrat, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 20px;
}
.section_items_wrap_grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 20px;
}
.section_items_wrap {
    width: 100vw;
    display: flex;
    justify-content: center;
    max-width: 1762px;
    margin-top: 50px;
}
.section_items_wrap_wrap {
    width: 100vw;
    display: flex;
    justify-content: center;
}
.section_item_wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 277px;
    height: 300px;
    border-radius: 14px;
    border: 2px solid #F79972;
    position: relative;
    padding-top: 70px;
}
.section_item_block{
    position: absolute;
    width: 105%;
    height: 105%;
    top: -5px;
    background: rgba(0, 0, 0, 0.54);
    backdrop-filter: blur(1px);
    border-radius: 14px;
}
.section_item_block_lock_img{
    margin-left: 100px;
    margin-top: 100px;
}
.section_item_wrap_purple{
    background: linear-gradient(180deg, rgba(189, 0, 255, 0.50) 0%, rgba(189, 0, 255, 0.00) 100%);
}
.section_item_wrap_red{
    background: linear-gradient(180deg, rgba(224, 64, 64, 0.50) 0.08%, rgba(224, 64, 64, 0.00) 99.92%);
}
.section_item_wrap_blue{
    background: linear-gradient(180deg, rgba(0, 148, 255, 0.50) 0.08%, rgba(0, 148, 255, 0.00) 99.92%);
}
.section_item_wrap_yellow{
    background: linear-gradient(180deg, rgba(255, 153, 0, 0.50) 0.08%, rgba(255, 153, 0, 0.00) 99.92%);
}
.section_item_wrap_gold{
    background: linear-gradient(180deg, rgba(255, 217, 82, 0.50) 0.08%, rgba(255, 229, 135, 0.00) 99.92%);
}
.section_item_wrap_silver{
    background: linear-gradient(180deg, rgba(244, 244, 244, 0.50) 0.08%, rgba(244, 244, 244, 0.00) 99.92%);
}
.section_item_bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 277px;
    height: 50px;
    border-radius: 0 0 14px 14px;
}
.section_item_bottom_purple{
    background: rgba(189, 0, 255, 0.30);
}
.section_item_bottom_red{
    background: rgba(224, 64, 64, 0.30);
}
.section_item_bottom_blue{
    background: rgba(0, 148, 255, 0.30);
}
.section_item_bottom_yellow{
    background: rgba(255, 153, 0, 0.30);
}
.section_item_bottom_gold{
    background: rgba(255, 217, 82, 0.30);
}
.section_item_bottom_silver{
    background: rgba(244, 244, 244, 0.30);
}
.section_item_bottom_text{
    color: #FFF;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
}
.section_item_medal_img{
    width: 84px;
    height: 70px;
}
.form_subs_wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 394px;
    height: 80px;
    margin-top: 70px;
    border-radius: 10px;
    background: #FFC700;
    cursor: pointer;
    box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 6px 4px 0px rgba(255, 255, 255, 0.30) inset;
}
.form_subs_div_wrap{
    width: 100%;
    display: flex;
    justify-content: center;
}
.form_subs_wrap:hover{
    box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 6px 4px 0px rgba(255, 255, 255, 0.30) inset, 0px 0px 30px 0px #FFC700;
}
.form_subs_text{
    color: #08122E;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
}
.section_header_wrap{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}
.main_wrap{
    position: relative;
}
.box_bg{
    position: absolute;
    margin-top: -55px;
    z-index: -50;
}
.section_item_wrap_img{
    width: auto;
    height: auto;
    max-width: 221px;
    max-height: 181px;
}
@media (max-width: 425px) {
    .section_item_top_wrap{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .form_subs_text{
        color: #08122E;
        text-align: center;
        font-family: Montserrat, serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 22px; /* 133.333% */
        text-align: center;
    }
    .form_subs_wrap{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 335px;
        height: 50px;
        margin-top: 50px;
        border-radius: 10px;
        background: #FFC700;
        cursor: pointer;
        box-shadow: 0px -5px 5px 0px rgba(0, 0, 0, 0.20) inset, 0px 6px 4px 0px rgba(255, 255, 255, 0.30) inset;
    }
    .section_item_wraps{
        z-index: 90;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 240px;
    }
    .section_items_wrap {
        width: 100vw;
        display: flex;
        justify-content: center;
        max-width: 1762px;
        margin-top: 14px;
    }
    .section_items_wrap_grid {
        display: grid;
        grid-template-columns: repeat(2, 158px);
        grid-gap: 10px;
    }
    .return_to_main_wrap{
        margin-left: 20px;
        margin-top: 30px;
        display: flex;
    }
    .gift_wrap{
        display: flex;
        gap: 12px;
        margin-top: 50px;
        width: 100vw;
        justify-content: center;
    }
    .section_item_top_text_warning{
        color: #E35959;
        font-family: Montserrat, serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        margin-left: 20px;
    }
    .section_item_top_text{
        color: #E6E6E6;
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 133.333% */
        letter-spacing: 0.3px;
    }
    .section_item_block_lock_img{
        margin-left: 56px;
        margin-top: 58px;
        width: 46px;
        height: 46px;
    }
    .section_item_block{
        position: absolute;
        width: 105%;
        height: 105%;
        top: -5px;
        background: rgba(0, 0, 0, 0.54);
        backdrop-filter: blur(1px);
        border-radius: 10px;
    }
    .section_item_wrap_img{
        width: auto;
        height: auto;
        max-width: 123px;
        max-height: 189px;
        margin-top: 30px;
    }
    .section_item_wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 158px;
        height: 174px;
        border-radius: 10px;
        border: 2px solid #F79972;
        position: relative;
        padding-top: 0px;
    }
    .section_item_bottom_text{
        color: #FFF;
        text-align: center;
        font-family: Montserrat, serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 140% */
    }
    .section_item_bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 158px;
        height: 30px;
        border-radius: 0 0 10px 10px;
    }
    .section_lvl_wrap{
        margin-top: 40px;
        min-width: auto;
        padding-left: 0px;
        padding-right: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 60px;
    }
}