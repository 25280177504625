.main{
    background-image: url("../../../../assets/background.webp");
    background-size: cover;

    background-repeat: no-repeat;
    height: 1218px;

}
.fighter_image{
    height: 757px;
    width: 522px;
}
.fighter_meta_wrap{
    background-image: url("../../../../assets/fighter_meta.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 131px;
    height: 56px;
}
.main_top_h1{
    margin-top: 80px;
    font-family: Montserrat, serif;
    font-size: 58px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.58px;
    background: linear-gradient(180deg, #FF3A3A 0%, #FFF500 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 209px;
}
.fighter_meta_name{
    font-family: Montserrat, serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    color: #FFF;
}

.fighter_meta_lvl{
    font-family: Montserrat, serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    color: #FFF;
}
.main_items_wrap{
    min-height: 456px;
}
.main_items{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
    grid-gap: 45px 20px;
    margin-top: 57px;
    width: 760px;
    margin-left: 209px;
}
.locked_overlay_text_top{
    text-align: center;
    font-size: 16px;
    line-height: 19.5px;
    color: #08122E;
    font-weight: 500;
    
}
.locked_overlay_text_bottom{
    text-align: center;
    font-size: 16px;
    line-height: 19.5px;
    color: #08122E;
    font-weight: 700;
    
}
.locked_overlay_text_top > span{
    font-weight: 600;
}
.locked_overlay_wrap{
    font-family: Montserrat, serif;
    padding-left: 30px;
    padding-right: 30px;
    gap: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -100%;
    left: 50%;
    height: 112px;
    width: 288px;
    background-color: #B3B5BD;
    z-index: 12;
    border-radius: 6px;
    outline-offset: 1.5px;
    outline-color: #EBEBEB;
}
.locked_overlay_wrap_medium{
    font-family: Montserrat, serif;
    padding-left: 30px;
    padding-right: 30px;
    gap: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -100%;
    left: 50%;
    height: 112px;
    width: 288px;
    background-color: #B3B5BD;
    z-index: 12;
    border-radius: 6px;
    outline-offset: 1.5px;
    outline-color: #EBEBEB;
}
.main_item_wrap{
    position: relative;
}
.main_item_medium{
    width: 110px;
    height: 122px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
}
.main_item_small{
    width: 100px;
    height: 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
}
.main_item_large{
    width: 300px;
    height: 277px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
}
.main_item_yellow{
    border: 3px solid #FDA62D;
    background: #3D2F00;
    box-shadow: 0px 0px 24px 0px rgba(255, 199, 0, 0.60);
}
.main_item_blue{
    border: 3px solid #C2C2C2;
    background: #003459;
    box-shadow: 0px 0px 24px 0px rgba(194, 194, 194, 0.60);
}
.main_item_red{
    border: 3px solid #F79972;
    background: #4B1414;
    box-shadow: 0px 0px 24px 0px rgba(247, 153, 114, 0.60);
}
.main_item_purple{
    border: 3px solid #C2C2C2;
    box-shadow: 0px 0px 24px 0px rgba(247, 153, 114, 0.6);
    background: linear-gradient(180deg, rgba(189, 0, 255, 0.30) 37.92%, rgba(189, 0, 255, 0.17) 100%);
}
.main_item_silver{
    border: 3px solid #C2C2C2;
    box-shadow: 0px 0px 24px 0px rgba(247, 153, 114, 0.60);
    background: linear-gradient(180.00deg, rgba(244, 244, 244, 0.3) 37.917%,rgba(244, 244, 244, 0.19) 100%);
}
.main_item_inactive_yellow{
    border: 3px solid #FDA62D;
    background: #3D2F00;
    box-shadow: 0px 0px 24px 0px rgba(255, 199, 0, 0.60);
}
.main_item_inactive_blue{
    border: 3px solid #C2C2C2;
    background: #003459;
    box-shadow: 0px 0px 24px 0px rgba(194, 194, 194, 0.60);
}
.main_item_inactive_red{
    border: 3px solid #F79972;
    background: #4B1414;
    box-shadow: 0px 0px 24px 0px rgba(247, 153, 114, 0.60);
}
.main_item_inactive_purple{
    border: 3px solid #C2C2C2;
    box-shadow: 0px 0px 24px 0px rgba(247, 153, 114, 0.6);
    background: linear-gradient(180deg, rgba(189, 0, 255, 0.30) 37.92%, rgba(189, 0, 255, 0.17) 100%);
}
.main_item_inactive_silver{
    border: 3px solid #C2C2C2;
    box-shadow: 0px 0px 24px 0px rgba(247, 153, 114, 0.60);
    background: linear-gradient(180.00deg, rgba(244, 244, 244, 0.3) 37.917%,rgba(244, 244, 244, 0.19) 100%);
}

.main_item_medal_medium{
    width: 44px;
    height: 37px;
    margin-top: -30px;
}
.main_item_medal_small{
    width: 44px;
    height: 37px;
    margin-top: -30px;
}
.main_item_medal_large{
    width: 72px;
    height: 61px;
    margin-top: 0px;
}
.main_item_bottom_div_small{
    width: 100px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main_item_bottom_div_medium{
    width: 110px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main_item_bottom_div_large{
    width: 300px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.main_item_bottom_div_lcoked{
    opacity: 0.6;
}
.main_item_bottom_div_yellow{
    background-color: rgba(253, 166, 45, 0.30);
}
.main_item_bottom_div_blue{
    background-color: rgba(194, 194, 194, 0.30);
}
.main_item_bottom_div_red{
    background-color: rgba(247, 153, 114, 0.30);
}
.main_item_bottom_div_purple{
    background-color: rgba(189, 0, 255, 0.3);
}
.main_item_bottom_div_silver{
    background-color: rgba(244, 244, 244, 0.3);;
}
.main_item_bottom_div_yellow_inactive{
    background-color: rgba(253, 166, 45, 0.30);
}
.main_item_bottom_div_blue_inactive{
    background-color: rgba(194, 194, 194, 0.30);
}
.main_item_bottom_div_red_inactive{
    background-color: rgba(247, 153, 114, 0.30);
}
.main_item_bottom_div_red_inactive{
    background-color: rgba(247, 153, 114, 0.15);
}
.main_item_bottom_div_purple_inactive{
    background-color: rgba(244, 244, 244, 0.15);
}
.main_item_bottom_text_medium{
    color: #FFF;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px; /* 140% */
}
.main_item_bottom_text_small{
    color: #FFF;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px; /* 140% */
}
.main_item_bottom_text_large{
    color: #FFF;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
}
.boxing_glove_wrap{
    cursor: pointer;
}
.boxing_glove{
    margin-left: 487px;
    margin-top: 60px;
}

.pagination_wrap{
    display: flex;
    gap: 117px;
    margin-top: 50px;
    align-items: center;
    margin-left: 451px;
}
.pagination_items{
    display: flex;
    gap: 16px;
}
.pagination_items_wrap{
    display: flex;
    gap: 50px;
    justify-content: space-between;
    align-items: center;
}
.pagination_wrap_bottom{
    display: flex;
    gap: 20px;
    opacity: 0.8;
}

.pagination_items_item{
    width: 12px;
    height: 3px;
    border-radius: 100px;
    background-color: #FFF;
    filter: drop-shadow(0px 0px 13px #0094FF);
}
.pagination_items_item_inactive{
    background-color: rgba(255, 255, 255, 0.30);
}
.right_scroll{
    transform: rotate(180deg);
}
.view_all{
    color: rgba(255, 255, 255, 0.60);
    font-family: Montserrat, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}
.view_all:hover{
    color: rgba(255, 255, 255, 1);
}
.view_all_wrap{
    display: flex;
    align-items: center;
    gap: 10px;
}
.btn_scroll{
    cursor: pointer;
}
.right_wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    margin-left: 140px;
}
.left_right_wrap{
    display: flex;
}

@media only screen and (min-width: 2048px) {
    .left_right_wrap{
        width: 100vw;
        justify-content: center;
    }
}

@media (max-width: 425px) {
    .main_top_h1{
        margin-top: 50px;
        font-family: Montserrat, serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.58px;
        background: linear-gradient(180deg, #FF3A3A 0%, #FFF500 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-left: 20px;
    }
    .left_right_wrap{
        display: flex;
        flex-direction: column;
    }
    .boxing_glove{
        margin-left: auto;
        margin-right: auto;
        margin-top: 15px;
    }
    .main_items{
        display: grid;
        grid-template-columns: repeat(3, 110px);
        grid-gap: 45px 20px;
        margin-top: 56px;
        width: auto;
        margin-left: 20px;
    }
    .pagination_wrap{
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 0px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .right_wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;
        margin-left: 0px;
    }
    .main{
        background-image: url("../../../../assets/background.webp");
        background-size: cover;
    
        background-repeat: no-repeat;
        width: 100vw;
        margin-bottom: 1000px;
        
    }
    .main_item_large{
        width: 158px;
        height: 174px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
    }
    .main_item_medal_large{
        width: 52px;
        height: 78px;
        margin-top: 0px;
    }
    .main_item_bottom_text_large{
        color: #FFF;
        text-align: center;
        font-family: Montserrat, serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 140% */
    }
    .main_item_bottom_div_large{
        width: 158px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}