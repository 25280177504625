/* Задаем темный фон всей странице */
.auth_page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1c1c1c; /* Темный фон */
  }
  
  /* Контейнер для окна авторизации */
  .auth_box {
    background-color: #2e2e2e; /* Чуть светлее, чем фон */

    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Легкая тень */
    text-align: center;
    width: 100%;
    height: 100%;
    max-height: 200px;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }
  
  /* Заголовок */
  .auth_box h1 {
    color: #ffffff;
    font-size: 24px;
  }
  