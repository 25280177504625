.instruction_wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 297px;
    border-radius: 6px;
    border: 1px solid #22335F;
    background: #101D40;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.30);
    margin: 70px 80px 210px;
}

.instruction_header{
    color: #E6E6E6;
    text-align: center;
    font-family: Montserrat, serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 125% */
    letter-spacing: 0.4px;
}

.instruction_span{
    color: rgba(255, 255, 255, 0.60);
    font-family: Montserrat, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
}

.instruction_header_wrap{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px;
    margin-top: 100px;
}
.instruction_h1{
    margin-top: 50px;
    margin-bottom: 30px;
    color: #FFF;
    font-family: Montserrat, serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 160% */
}
.box_bg{
    position: absolute;
    margin-top: -5px;
    z-index: -50;
}
@media (max-width: 425px) {
    .instruction_header_wrap{
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 14px;
        margin-top: 50px;
    }
    .instruction_wrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px 20px;
        border-radius: 6px;
        border: 1px solid #22335F;
        background: #101D40;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.30);
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 30px;
    }
    .instruction_h1 {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0%;
        margin-top: 30px;
        margin-bottom: 20px;
      }
      .instruction_header{
        max-width: 300px;
        color: #E6E6E6;
        text-align: center;
        font-family: Montserrat, serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 125% */
        letter-spacing: 0.4px;
    }
    .instruction_span{
        color: rgba(255, 255, 255, 0.60);
        font-family: Montserrat, serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
    }
  }